html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;

  &.dark {
    background: var(--lightgrey100);
  }

  &.noscroll {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Siemens Sans', Tahoma, Geneva, sans-serif;
}

h6 {
  font-size: 11px !important;
}


h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
ul,
li {
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
}

ol {
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
  text-decoration: none;
}

menu {
  padding: 0;
  margin: 0;
}

button {
  border: none;
  outline: none;
}

.display-none {
  display: none !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
