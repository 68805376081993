@use 'libs/shared/util-styles/src/mixins';

$scalable-overlays: '.mat-mdc-dialog-container, .cdk-overlay-connected-position-bounding-box';

@mixin responsive-zoom-styles {
  @include mixins.mq(desktop-s-width) {
    #{$scalable-overlays} {
      zoom: 0.8;
    }

    ion-app {
      left: 0;
      top: 0;
      zoom: 0.8;
      min-height: 125vh !important;
      min-width: 125vw !important;
    }

    ion-content {
      > .container {
        > .inner-content {
          min-height: calc(125vh - 75px) !important;
          max-height: calc(125vh - 75px) !important;
        }
      }
    }

    .lay-wrapper,
    .app-content {
      width: 125vw !important;
    }

    app-home,
    .faq-sidebar {
      height: 125vh !important;
      min-height: 125vh !important;
      max-height: 125vh !important;
    }
    #equity-simplebar {
      max-height: calc(125vh - 200px);
      min-height: calc(125vh - 200px);
    }
    .main-tab-height {
      height: calc(125vh - 160px) !important;
      min-height: calc(125vh - 160px) !important;
    }
  }

  @include mixins.mq(desktop-xxs-width) {
    #{$scalable-overlays} {
      zoom: 0.5;
    }
    ion-app {
      left: 0;
      top: 0;
      zoom: 0.5;
      min-height: 200vh !important;
      min-width: 200vw !important;
    }

    ion-content {
      > .container {
        > .inner-content {
          min-height: calc(200vh - 75px) !important;
          max-height: calc(200vh - 75px) !important;
        }
      }
    }

    .lay-wrapper,
    .app-content {
      width: 200vw !important;
    }

    app-home,
    .faq-sidebar {
      height: 200vh !important;
      min-height: 200vh !important;
      max-height: 200vh !important;
    }
    #equity-simplebar {
      max-height: calc(200vh - 200px);
      min-height: calc(200vh - 200px);
    }
    .main-tab-height {
      height: calc(200vh - 160px) !important;
      min-height: calc(200vh - 160px) !important;
    }
  }
}

@media not print {
  // desktop styles
  @media (pointer: fine), (pointer: none) {
    @include responsive-zoom-styles;
  }

  // touch desktop styles
  @media (pointer: fine) and (any-pointer: coarse) {
    @include responsive-zoom-styles;
  }
}
