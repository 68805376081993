$scrollbar-width: 10px;
$border-radius: 30px;

// Scroll Bar Mixins
@mixin primary-scrollbar-vertical() {
  .simplebar-track.simplebar-horizontal {
    display: none;
  }

  .simplebar-content {
    overflow: hidden;
    padding: calc(20px / 2) 0 !important;
  }

  .simplebar-track.simplebar-vertical {
    width: $scrollbar-width;
    border-radius: $border-radius;
    background: var(--lightgrey50);
    margin: calc(30px / 2) 0 !important;

    .simplebar-scrollbar:before {
      border-radius: $border-radius;
      width: $scrollbar-width;
      background: var(--darkgrey900);
      opacity: 1;
      left: 0 !important;
      right: 0 !important;
      top: 0 !important;
      bottom: 0 !important;
    }
  }

  &.modal-appearance {
    .simplebar-content {
      padding: 0 !important;
      margin: calc(20px / 2) 0 !important;
    }

    .simplebar-track.simplebar-vertical {
      background: rgb(148, 153, 153);
      margin: calc(30px / 2) 0 !important;
    }

    .simplebar-scrollbar:before {
      background: rgb(29, 109, 133);
    }
  }

  &.sidebar-appearance {
    .simplebar-track.simplebar-vertical {
      background: rgb(109, 205, 204);
    }

    .simplebar-scrollbar:before {
      background: rgb(0, 147, 168);
    }
  }

  &.no-space {
    .simplebar-content {
      padding: 0 !important;
    }

    .simplebar-track.simplebar-vertical {
      margin: 0 !important;
    }
  }

  &.equity {
    .simplebar-content-wrapper {
      scroll-behavior: smooth !important;
    }

    .simplebar-content {
      padding: 0px !important;
    }

    .simplebar-track.simplebar-vertical {
      margin: 30px 20px !important;
    }

    .simplebar-scrollbar:before {
      background: var(--lightgrey400) !important;
    }
  }

  &.dc-modification {
    .simplebar-track.simplebar-vertical {
      margin-right: 0px !important;
      margin-bottom: 0px !important;
    }
  }
}

@mixin contrast-scrollbar-vertical() {
  @include primary-scrollbar-vertical();

  .simplebar-track.simplebar-vertical {
    background: var(--petrol300);
  }

  .simplebar-scrollbar:before {
    background: white;
  }
}

@mixin primary-scrollbar-horizontal() {
  .simplebar-track.simplebar-vertical {
    display: none;
  }

  .simplebar-track.simplebar-horizontal {
    height: 11px;
    border-radius: $border-radius;
    background: var(--lightgrey50);
    opacity: 0.7;
    margin-right: 20px;
    z-index: 100;

    .simplebar-scrollbar:before {
      border-radius: $border-radius;
      background: var(--darkgrey900);
      opacity: 0.8;
      left: 0 !important;
      right: 0 !important;
      top: 0 !important;
      bottom: 0 !important;
    }
  }

  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    height: 11px !important;
    top: 0 !important;
  }
}

@mixin primary-scrollbar-both() {
  @include primary-scrollbar-vertical;
  @include primary-scrollbar-horizontal;

  .simplebar-track.simplebar-vertical {
    display: block;
  }
  .simplebar-track.simplebar-horizontal {
    display: block;
  }
  .simplebar-content {
    overflow: unset;
  }
}
