.dynamic-circle-button {
  border-radius: 25px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-blend-mode: darken;

  > button {
    background-color: var(--lightgrey50) !important;
    border: 2px solid var(--lightgrey200) !important;
    border-radius: 18px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
