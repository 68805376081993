//
app-sidebar {
  .components {
    background: var(--darkgrey900) !important;
    > *:nth-child(even) {
      background: none !important;
    }
    > *:nth-child(even),
    > *:nth-child(odd) {
      border-bottom: 1px solid var(--darkgrey800);
    }
    .overview-section > mat-icon {
      color: var(--darkgrey900) !important;
    }
    > *:last-child {
      border-bottom: 0px !important;
    }
  }
}

// app-tab-selection {
//     .selection-part .selection-item.tab-selected {
//         p {
//             color: white !important;
//         }
//     }
// }

app-season-overview {
  background: white !important;

  .month-part--container {
    background: var(--lightgrey50);
    border: 2px solid var(--lightgrey300) !important;
    border-radius: 22px !important;
  }

  *p {
    color: var(--purple900) !important;
  }

  .month-text {
    text-transform: unset !important;
    background-color: unset !important;
    top: -2px !important;
    width: calc(100% - 16px);
    text-align: center;
    padding: 10px !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    &.active {
      color: white !important;
      background: var(--darkgrey800) !important;
    }
  }
  .week-text {
    text-transform: unset !important;
    background-color: unset !important;
    top: -2px !important;
    width: calc(100% - 16px);
    text-align: center;
    padding: 10px !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    transform: translate(-50%, 0%) !important;
    border-color: transparent !important;
    border: 0px !important;
    &.active-week {
      color: white !important;
      background: var(--darkgrey800) !important;
    }
  }
  .week-part {
    position: absolute;
    width: 100%;
    padding-bottom: 10px;
    padding-top: 8px;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    bottom: 0px !important;
    &.active {
      background: var(--darkgrey800) !important;
      p {
        color: white !important;
      }
    }
  }
  .day-part {
    &.active {
      background: var(--darkgrey800) !important;
      p {
        color: white !important;
      }
    }
  }
  .week-text {
    border: 0px solid var(--lightgrey300) !important;
    // background: var(--lightgrey50) !important;
    &.active-week {
      color: white !important;
      background: var(--darkgrey800) !important;
    }
    &--short {
      height: unset !important;
      width: 100% !important;
      line-height: unset !important;
    }
  }
}

app-topic-action-bar {
  .all-item {
    // border: 0px !important;
    border-width: 2px !important;
    background-color: var(--lightgrey200) !important;
    border-color: var(--lightgrey200) !important;

    p {
      // color: white !important;
      min-width: 60px !important;
    }

    &.active {
      // background: white !important;
      p {
        color: var(--lightgrey200) !important;
      }
    }
  }

  .cat-items {
    border-radius: 20px !important;

    &.active {
      p,
      i {
        color: white !important;
      }

      &--News {
        background-color: white !important;
        border: 2px solid var(--darkgrey600) !important;
        p,
        i {
          color: var(--darkgrey600) !important;
        }
      }
      &--Announcement {
        background-color: white !important;
        border: 2px solid var(--petrol700) !important;
        p,
        i {
          color: var(--petrol700) !important;
        }
      }
      &--Task {
        background-color: white !important;
        border: 2px solid var(--blue500) !important;
        p,
        i {
          color: var(--blue500) !important;
        }
      }
    }
  }
}

.season-container {
  padding: 0px !important;
  .left {
    padding: 20px;
    background: linear-gradient(to right, var(--darkgrey800), var(--darkgrey900), var(--purple800), var(--darkgrey900), var(--darkgrey800)); // TODO: HERE
    margin: 0px !important;
  }
}
.current-month {
  .indicator {
    top: 30px !important;
    .bar {
      background: var(--darkgrey800);
      border-width: 0px !important;
      border-radius: 100px;
      width: 3px !important;
      // border-left: 2px solid var(--purple800) !important;
      height: calc(100% - 80px) !important;
      &--month {
        // border-left: 2px solid var(--purple800) !important;
        height: calc(100% - 70px) !important;
      }
    }
    .bowl {
      display: none !important;
      background-color: var(--purple800) !important;
      left: 1px;

      p {
        display: none;
      }

      &--month {
        background-color: var(--purple800) !important;
        left: 1px;

        p {
          display: none;
        }
      }

      &--month-top {
        width: 12px !important;
        height: 12px !important;
        border-radius: 50%;
        display: block;
        position: absolute;
        top: 0px;
        background-color: var(--purple800) !important;
        left: 2px !important;
      }
    }
  }
}

app-topic-list {
  background: var(--lightgrey200) !important;
  // margin-top: -10px;
  padding-top: 10px;
  transition: all 300ms ease-in-out;
  &.minimized {
    padding-top: 0px;
  }

  h4 {
    display: none;
  }
}

.switch {
  .se-switches {
    i {
      cursor: pointer;
      padding: 10px;
      border-radius: 50%;
      background-color: var(--lightgrey200) !important;
      margin: 20px 0px;
      color: var(--darkgrey800) !important;
      &.activ {
        color: var(--lightgrey200) !important;
        background-color: var(--darkgrey800) !important;
      }
    }

    button:focus i {
      box-shadow: 0px 0px 10px var(--darkgrey800);
    }
  }
  .switch-container {
    display: none !important;
  }
}

app-service-provider-list {
  .header {
    h3,
    p {
      color: white !important;
    }
  }
  .container {
    a {
      text-decoration: underline;
      font-weight: bold;
      margin-top: 0px !important;
    }
  }
}

app-apps {
  .mat-icon-no-color {
    color: white !important;
    font-size: 25px !important;
  }
}

.profile-info p {
  color: var(--darkgrey900) !important;
}

.mdc-switch__track:after {
  background-image: linear-gradient(to right, var(--purple800), var(--darkgrey900)) !important;
}

app-user-menu {
  .profile-info {
    p {
      &:first-child {
        color: var(--purple900) !important;

        &::after {
          color: var(--purple900) !important;
        }
      }
    }
  }

  .menu-entry {
    opacity: 1 !important;
    background-color: var(--purple600) !important;
  }
}

ion-avatar {
  background: var(--purple900) !important;
  border: solid 0px var(--purple900) !important;
  .mat-icon {
    color: white !important;
  }
}

.notifications {
  background: var(--purple800) !important;
}

.faq-search {
  background-color: white !important;
}

.header-icons {
  .m-icon {
    margin-right: 15px !important;
    &--faq-icon {
      background: var(--purple800) !important;
    }
    &--lang-icon {
      width: 40px;
      height: 40px;
      background: var(--purple800);
      text-align: center;
      border-radius: 50%;
      i {
        color: white !important;
        margin: auto;
        font-size: 23px !important;
      }
    }
  }
  .search-icon {
    background: var(--purple800) !important;
    i {
      font-size: 23px !important;
      margin: auto;
    }
  }
}

.burger-wrapper {
  background-color: var(--darkgrey900) !important;

  &:focus {
    background-color: var(--darkgrey800) !important;
  }
}

app-collapsed-bar {
  background: var(--darkgrey900) !important;
  > *:nth-child(even) {
    background: none !important;
  }
  > *:nth-child(even),
  > *:nth-child(odd) {
    border-bottom: 1px solid var(--darkgrey800);
  }
}

.no-topics {
  color: var(--purple900) !important;
}

.toggle-icon {
  background: var(--purple800) !important;
}

.topic-color {
  box-shadow: none !important;
  width: 30px !important;
  &--Task {
    background-color: var(--blue500) !important;
  }
  &--News {
    background-color: var(--darkgrey600) !important;
  }
  &--Announcement {
    background-color: var(--petrol700) !important;
  }
}

.date-icon {
  &--Task::before {
    color: var(--blue500) !important;
  }
  &--News::before {
    color: var(--darkgrey600) !important;
  }
  &--Announcement::before {
    color: var(--petrol700) !important;
  }
}

app-topic-action-bar {
  .border {
    &--Task {
      border-color: var(--blue500) !important;
      background: var(--blue500) !important;
    }
    &--News {
      border-color: var(--darkgrey600) !important;
      background: var(--darkgrey600) !important;
    }
    &--Announcement {
      border-color: var(--petrol700) !important;
      background: var(--petrol700) !important;
    }
  }
  .color--Task,
  .color--Announcement,
  .color--News {
    color: white !important;
  }
}

.task-event-item {
  &--Task {
    background-color: rgba(var(--blue200-rgb), 0.6) !important;
    &.highlight {
      background-color: var(--blue500) !important;
    }
  }
  &--News {
    background-color: transparent !important; // var(--darkgrey600-half) !important;
    &.highlight {
      background-color: transparent !important; // var(--darkgrey600) !important;
    }
  }
  &--Announcement {
    background-color: transparent !important; // var(--petrol400) !important;
    &.highlight {
      background-color: transparent !important; // var(--petrol700) !important;
    }
  }
  p {
    color: white !important;
  }
  &.highlight {
    .cal-bottom-icon {
      &--News {
        border-color: var(--darkgrey600) !important;
        background: var(--darkgrey600) !important;
        &::before {
          color: white !important;
        }
      }
      &--Announcement {
        border-color: var(--petrol700) !important;
        background: var(--petrol700) !important;
        &::before {
          color: white !important;
        }
      }
    }
  }
  .cal-bottom-icon {
    background: var(--lightgrey50) !important;
    &--News {
      border-color: var(--darkgrey600) !important;
      &::before {
        color: var(--darkgrey600) !important;
      }
    }
    &--Announcement {
      border-color: var(--petrol700) !important;
      &::before {
        color: var(--petrol700) !important;
      }
    }
  }
}

app-calendar {
  // .simplebar-horizontal {
  //     display: none;
  // }
  background: radial-gradient(rgba(77, 33, 122, 0.6), rgba(27, 21, 52, 0.6)) !important;
}

app-topics-carousel {
  margin-top: -60px;
  padding-bottom: 20px;
  .car-top {
    .control {
      background: var(--lightgrey400) !important;
    }
  }
}

body .primary-scrollbar-vertical .simplebar-scrollbar:before {
  background: var(--darkgrey900) !important;
}

app-topics-collapsed-bar {
  border-top: 2px solid var(--lightgrey200);
  background: var(--lightgrey300) !important;
}

.mdc-tab-indicator--active .mdc-tab-indicator {
  background-color: var(--darkgrey500) !important;
  > .mdc-tab-indicator__content {
    border-color: var(--darkgrey500);
  }
}

button {
  transition: all 300ms ease-in-out;
}

.carousel-flip {
  .close-btn {
    background: var(--purple800) !important;
  }
}

app-allocation-header,
app-org-review-header {
  background: linear-gradient(to right, var(--darkgrey900), var(--darkgrey900), var(--purple800), var(--darkgrey900), var(--darkgrey900)) !important; // TODO: HERE
}
app-allocation-task-list-item {
  .vertical-line,
  .horizontal-line {
    background: var(--purple800) !important;
  }
}

app-allocation-box,
app-allocation-kpi-header-bars {
  .person-container.children-available .toggle-children {
    background: var(--purple800) !important;
  }
  .section,
  .dot {
    &--base {
      background: var(--purple800) !important;
    }
    &--bonus {
      background: var(--darkgrey900) !important;
    }
    &--equity {
      background: var(--purple700) !important;
    }
  }
  .connector {
    &--base {
      background: var(--purple300) !important;
    }
    &--bonus {
      background: var(--darkgrey500) !important;
    }
    &--equity {
      background: var(--purple400) !important;
    }
  }
}

.breadcrumb-person {
  background: var(--purple800) !important;
  &:nth-child(3) {
    background: var(--purple800) !important;
  }
}

// EQUITY:

// content specific adjustments
app-equity-chapter,
app-begin-tour .mat-mdc-tab-body {
  p,
  a,
  .p,
  .a {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    color: var(--darkgrey900);
  }

  i,
  .i {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }

  a,
  .a {
    // font-weight: bold;
    font-family: Siemens Sans bold;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    text-decoration: underline;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--darkgrey900) !important;
  }
}

app-equity,
app-incentive,
.cms-item {
  .contrast {
    background: var(--darkgrey900);
  }

  .max-width {
    max-width: 880px;
    margin: auto;
  }

  .color-indicator {
    background: var(--purple800) !important;
  }

  app-equity-header {
    > .container {
      background: linear-gradient(to right, var(--darkgrey900), var(--purple800), var(--darkgrey900)) !important; // TODO: HERE
      box-shadow: none !important;
    }
  }
}

app-document-center {
  .doc-center-container {
    > .container {
      background: linear-gradient(to right, var(--darkgrey900), var(--purple800), var(--darkgrey900)) !important; // TODO: HERE
      box-shadow: none !important;
    }
  }
}

app-allocation-task-list-item {
  .container {
    .expand {
      background: var(--purple800) !important;
    }
  }
}
app-directs-overview {
  .title-container {
    background: linear-gradient(to right, var(--darkgrey900), var(--darkgrey900), var(--purple800), var(--darkgrey900), var(--darkgrey900)) !important;
    mat-icon {
      color: var(--purple800) !important;
    }
  }
  .directs-section {
    h4 {
      color: var(--purple800) !important;
    }
  }
}
