.proposal-amount {
  .mat-form-field-disabled {
    .mat-mdc-form-field-flex {
      align-items: center;
      
      .mat-mdc-form-field-infix {
        border-top: 0;
      }
    }
  }
}