@use './mixins';

.ios .te-mgmt-analytics {
  display: none;
}

button:not([disabled]):hover {
  cursor: pointer;
}

.cat-items {
  background-color: white;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

app-fv-left {
  ul,
  ol {
    padding-left: 20px;
  }
}

coin-equity-expand-box {
  .mat-expansion-indicator::after {
    color: white !important;
  }
}
.select-emu {
  .mdc-linear-progress__primary-bar,
  .mdc-linear-progress__bar-inner {
    background-color: var(--purple500) !important;
    border-color: var(--purple500) !important;
  }
}

.mat-mdc-checkbox-ripple .mat-ripple-element,
.mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background,
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background-color: var(--petrol500) !important;
  .mdc-checkbox__checkmark-path {
    color: white !important;
  }
}

.mat-mdc-select-panel {
  coin-input {
    width: 100%;
    .mat-mdc-form-field-infix {
      padding: 7px !important;
    }
    .mat-mdc-text-field-wrapper {
      padding-bottom: 0px;
    }
  }
}

coin-html-editor,
.expand-box-content,
.app-faq-answer-level {
  .select-font,
  .select-font-size,
  #foregroundColorPicker-,
  #backgroundColorPicker-,
  #insertHorizontalRule-,
  #backgroundColorPicker-editor,
  #foregroundColorPicker-editor,
  #insertVideo-editor,
  #insertHorizontalRule-editor,
  #clearFormatting-editor,
  #indent-editor,
  #toggleEditorMode-,
  #insertVideo-,
  #insertImage-,
  #outdent-editor {
    display: none !important;
  }
  span {
    font-family: Siemens Sans;
    font-size: 1.2rem !important;
    line-height: 1.45rem !important;
    color: var(--darkgrey900);
  }
  b {
    font-weight: initial;
    font-family: Siemens Sans bold;
  }
  div,
  td {
    font-family: Siemens Sans !important;
    a {
      text-decoration: underline;
      font-weight: bold;
      color: var(--purple700) !important;
    }
    ul {
      list-style-type: disc;
    }
    ul,
    ol {
      padding-left: 20px;
      li span {
        font-family: 'Siemens Sans', Tahoma, Geneva, sans-serif;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0.02rem;
        font-weight: 400;
        color: var(--darkgrey900);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: font-size 0.3s ease-in-out;
      }
    }
    table {
      width: 100%;
      td,
      th {
        // border: 1px solid lightblue;
        padding: 5px;
      }
      // tr:nth-child(even) {
      //   background:var(--lightgrey200);
      // }
    }
  }
}

app-equity-text {
  p,
  span {
    font-family: Siemens Sans;
    b {
      font-weight: initial;
      font-family: Siemens Sans bold;
    }
  }
  a {
    font-family: Siemens Sans Black;
    text-decoration: underline;
    color: var(--darkgrey800);
  }
}

app-root {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  contain: layout size style;
  z-index: 0;
}

.text-to-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.highlight-this-part:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 100vw rgba(0, 0, 0, 0.8);
  // z-index: 1000;
}

@media screen and (orientation: portrait) {
  .highlight-this-part:after {
    box-shadow: 0px 0px 0px 100vh rgba(0, 0, 0, 0.8);
  }
}

coin-all-cms-news-items {
  p,
  span,
  li,
  div,
  a,
  b {
    font-family:
      Siemens Sans,
      Tahoma,
      Geneva,
      sans-serif;
    font-size: 0.75rem !important;
    line-height: 1.05rem !important;
    letter-spacing: 0.02rem !important;
    &.download-file-item {
      text-decoration: underline;
    }
  }
  b {
    font-family: Siemens Sans black;
  }
  li {
    margin-left: 20px !important;
  }
  a {
    text-decoration: underline;
    font-weight: bold;
    color: var(--purple500) !important;
    font-size: inherit;
  }
  a {
    text-decoration: underline;
    font-weight: bold;
    color: var(--purple500) !important;
    font-size: inherit;
  }
}

.close-button {
  z-index: 9999;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  background: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transform: translate(50%, -50%);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  mat-icon {
    color: var(--purple700);
  }
}

.close-icon-and-transition {
  transition: all 400ms ease-in-out !important;
  .mat-mdc-dialog-container {
    overflow: unset !important;
    display: flex !important;
    .mdc-dialog__container {
      flex: 1;
      background: transparent !important;
      .mdc-dialog__surface {
        overflow: visible;
      }
    }
  }
}

.mat-mdc-dialog-no-background .mat-mdc-dialog-container {
  background-color: transparent !important;
  box-shadow: transparent 0px 0px !important;
}

mat-dialog-container {
  border-radius: 15px !important;
  padding: 0 !important;
  // overflow: visible !important;
}

.half-width {
  max-width: 50vw !important;
  min-width: 600px !important;
}

.less-visible {
  opacity: 0.2;
}

.invisible-tabs {
  > .mat-mdc-tab-header {
    display: none;
  }
}

.backdrop-hide-remove {
  &.show {
    background: rgba(0, 0, 0, 0.8);
  }
  &.no-event {
    pointer-events: none;
  }
}

.org-planning-edit-organisation-dialog-overlay-pane {
  max-width: 95vw;
  flex: 0 1 34%;
}

body {
  height: 100%;
  max-height: 100%;

  .primary-scrollbar-vertical {
    @include mixins.primary-scrollbar-vertical();
  }

  .contrast-scrollbar-vertical {
    @include mixins.contrast-scrollbar-vertical();
  }

  .primary-scrollbar-horizontal {
    @include mixins.primary-scrollbar-horizontal();
  }

  .primary-scrollbar-both {
    @include mixins.primary-scrollbar-both();
  }

  .d1r-dialog-container {
    border-radius: 15px;
    overflow: hidden;
  }

  .d1r-dialog-container-bo {
    mat-dialog-container {
      border-radius: 15px !important;
      overflow: visible;
    }
  }

  .gradient-fade {
    .simplebar-content-wrapper {
      -webkit-mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1)
      ) !important;
      mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1)
      ) !important;
    }
  }

  .dialog-close-icon {
    position: absolute;
    width: 30px;
    height: 30px;
    top: -15px;
    right: -15px;
    background: var(--lightgrey300);
    padding: 7px;
    border-radius: 50%;
    cursor: pointer;
    transition: background 300ms ease-in-out;
    &:hover {
      background: var(--darkgrey500);
    }
  }
  app-directs-overview {
    [id^='mat-tab-label-'] {
      background: transparent !important;
    }
    .mdc-tab__content {
      color: white !important;
      font-family: Siemens Sans black;
    }
    .mdc-tab-indicator--active .mdc-tab-indicator {
      background-color: var(--darkgrey500);
    }
    mat-tab-header,
    .mat-mdc-tab-label-container,
    [id^='mat-tab-label-'] {
      overflow: visible !important;
    }
    .mdc-tab__content {
      p {
        position: absolute;
        right: 0px;
        top: 0px;
        transform: translate(100%, -10%);
        /* color: white; */
        font-family: Siemens Sans black;
        background: white;
        padding: 5px;
        border-radius: 50%;
        font-size: 12px;
        min-width: 26px;
      }
    }
  }

  app-home {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }

  .custom-hide-down {
    @media only screen and (max-width: 1536px) {
      display: none;
    }
  }

  app-season-overview {
    background: rgb(86, 106, 116);
  }
}

snack-bar-container {
  margin-top: 70px !important;
}

.dashed-underline {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
}

.finished-changeable {
  > span {
    font-family: 'Siemens Sans Bold', Tahoma, Geneva, sans-serif;
  }
  font-family: 'Siemens Sans Bold', Tahoma, Geneva, sans-serif;
}

.snow {
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url('/assets/images/snow1.png'), url('/assets/images/snow2.png'), url('/assets/images/snow3.png');
  z-index: 2;
  -webkit-animation: snow 10s linear infinite;
  -moz-animation: snow 10s linear infinite;
  -ms-animation: snow 10s linear infinite;
  animation: snow 10s linear infinite;
  pointer-events: none;
}

@keyframes snow {
  0% {
    background-position:
      0 0px,
      0 0px,
      0 0;
  }
  50% {
    background-position:
      500px 500px,
      100px 200px,
      -100px 150px;
  }
  100% {
    background-position:
      500px 1000px,
      200px 400px,
      -100px 300px;
  }
}

@-moz-keyframes snow {
  0% {
    background-position:
      0 0px,
      0 0px,
      0 0;
  }
  50% {
    background-position:
      500px 500px,
      100px 200px,
      -100px 150px;
  }
  100% {
    background-position:
      400px 1000px,
      200px 400px,
      100px 300px;
  }
}

@-webkit-keyframes snow {
  0% {
    background-position:
      0 0px,
      0 0px,
      0 0;
  }
  50% {
    background-position:
      500px 500px,
      100px 200px,
      -100px 150px;
  }
  100% {
    background-position:
      500px 1000px,
      200px 400px,
      -100px 300px;
  }
}

@-ms-keyframes snow {
  0% {
    background-position:
      0 0px,
      0 0px,
      0 0;
  }
  50% {
    background-position:
      500px 500px,
      100px 200px,
      -100px 150px;
  }
  100% {
    background-position:
      500px 1000px,
      200px 400px,
      -100px 300px;
  }
}

.percent-input {
  transition: all 400ms ease-in-out;
}
@media screen and (max-width: 1150px) {
  .percent-input {
    padding-right: 10% !important;
    transition: all 400ms ease-in-out;
  }
}
@media screen and (max-width: 1050px) {
  .percent-input {
    padding-right: 20% !important;
    transition: all 400ms ease-in-out;
  }
}

@media screen and (max-width: 900px) {
  .percent-input {
    padding-right: 30% !important;
    transition: all 400ms ease-in-out;
  }
}

.green-snackbar {
  background: var(--green300);
}

.red-snackbar {
  background: var(--red300);
}

.mdc-linear-progress__primary-bar,
.mdc-linear-progress__bar-inner {
  background-color: var(--purple500) !important;
  border-color: var(--purple500) !important;
}

.mat-badge-content {
  background: var(--purple700);
}

.fadeIn {
  -webkit-animation: fadeIn 2s forwards;
  -moz-animation: fadeIn 2s forwards;
  animation: fadeIn 2s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.slide {
  position: relative;
  transition: all 400ms ease-in-out;
}

.slideMeIn  {
  -webkit-animation: slideIn 1s forwards;
  -moz-animation: slideIn 1s forwards;
  animation: slideIn 1s forwards;
}

@-webkit-keyframes slideIn {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideIn {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideIn {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}

.forecast-amount {
  display: flex;
  background: repeating-linear-gradient(45deg, rgb(220, 220, 220), rgb(220, 220, 220) 5px, rgb(192, 192, 192) 1px, rgb(192, 192, 1922) 6px);
  border-top: 1px solid rgb(55, 78, 88);
  border-bottom: 1px solid rgb(55, 78, 88);
  transition: all 800ms ease-in-out;
  p {
    color: rgb(55, 78, 88);
    font-family: Siemens Sans bold;
  }
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;

  border-bottom: 6px solid rgb(55, 78, 88);
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 6px solid rgb(55, 78, 88);
}

.mat-mdc-slide-toggle.mat-primary .mdc-switch__handle::after,
.mat-mdc-slide-toggle.mat-primary.mdc-switch-checked .mdc-switch__handle::after {
  background-color: white !important;
}
.mdc-switch__track:after {
  background-image: linear-gradient(to right, var(--blue400), var(--petrol700), var(--petrol500)) !important;
}

.mdc-switch__track {
  height: 22px !important;
  border-radius: 11px !important;
}

.mat-mdc-input-element:not(.bold) {
  font-family: Siemens Sans !important;
}

.dashboard-image {
  height: 100%;
  background: url('/assets/images/mac_skyline.png') center no-repeat;
  background-size: cover;
  background-position: center;
}

.transparent-dialog-panel {
  > mat-dialog-container {
    box-shadow: none;
    background: transparent;
  }
}

.no-border-input {
  input {
    border: 0px !important;
  }
}

.no-tab-header {
  > .mat-mdc-tab-header {
    display: none !important;
  }
}

.full-tab-height {
  .mat-mdc-tab-body-wrapper {
    flex: 1 !important;
  }
}

app-directs,
app-directs-overview {
  [id^='mat-tab-label-'] {
    &:first-child {
      margin-left: auto !important;
    }
    &:last-child {
      margin-right: auto !important;
    }
    .mdc-tab__content,
    .mdc-tab__text-label {
      color: white !important;
      font-family: Siemens Sans black !important;
    }
  }
}
@media only screen and (min-width: 1920px) {
  app-topic-item {
    .date-icon {
      &::before {
        font-size: 38px !important;
      }
    }
  }
}

.faq-sidebar {
  .mat-mdc-tab-header {
    display: none !important;
  }

  .mat-mdc-tab-body,
  .mat-mdc-tab-body-content,
  .mat-mdc-tab-body-wrapper {
    height: 100%;
  }
}

.faq-answer-items-holder {
  td  {
    p {
      padding: 6px !important;
    }
  }
  tr:nth-child(even) {
    background: var(--lightgrey200);
  }
  a {
    font-family: Siemens Sans Black;
    text-decoration: underline;
    color: var(--darkgrey800);
  }
  td {
    font-family: 'Siemens Sans', Tahoma, Geneva, sans-serif;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.02rem;
    font-weight: 400;
    color: var(--darkgrey900);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: font-size 0.3s ease-in-out;
    padding: 8px;
  }
  ul {
    padding-left: 15px;
  }
}

.mdc-tooltip {
  font-family: Siemens Sans !important;
  font-size: 12px !important;
}

.mat-mdc-tab-header-pagination {
  display: none !important;
}

.cp-slide-toggle {
  .mdc-switch__handle {
    height: 18px;
    width: 18px;
    &::after {
      background: white !important;
    }
  }
}

.readonly {
  pointer-events: none;
  user-select: none;
}
