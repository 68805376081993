/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 */

@use 'simplebar/dist/simplebar.css';

/* Structured Packages */
@use './styles/_global/normalize';
@use './styles/_global/materialicons';
@use './styles/_global/typography';
@use './styles/_global/base';
@use './styles/_global/cms';
@use './styles/layouts/lay-wrapper';
@use './styles/atoms/a-text';

/* To add more visual modifications use this SCSS below */
@use './styles/_global/additionalmods';
@use './styles/_global/print';
@use './styles//_global/accessability';

/* Add Global Organizational Stylings here */
@use './styles/_organizational-packages/siemens-energy';

@use 'ngx-toastr/toastr';

@use 'input-base';
@use './styles/components/dynamic-circle-button';
@use './styles/components/error-marks';
@use './styles/components/mat-overrides';
@use './styles/components/text-dialog';

/* Quill Editor */
@use 'quill/dist/quill.core.css' as quill-core;
@use 'quill/dist/quill.bubble.css' as quill-bubble;
@use 'quill/dist/quill.snow.css' as quill-snow;

/* Feature specific base overwrites */
@use './styles/features/succession-management.scss';

/* Handsontable default styles */
@use 'coin-handsontable';
