@media print {
  body,
  ion-content,
  ion-router-outlet,
  .app-root,
  .split-pane,
  .ion-page,
  ion-app,
  ion-nav {
    contain: none;
    -webkit-print-color-adjust: exact;
  }

  @page {
    size: A3 !important;
  }

  .equity-width-limited {
    max-width: unset;
  }

  body,
  ion-nav {
    // overflow: initial !important;
  }

  body {
    // position: static;
    display: block;
    overflow: initial;
    height: fit-content !important;
    max-height: initial !important ;
    position: relative;
  }

  .scroll-content,
  .dynamic-content {
    position: relative;
    overflow: visible !important;
  }

  ion-router-outlet {
    width: 100% !important;
  }

  [ion-content-print] {
    display: block !important;
    overflow-y: visible !important;
    --overflow: visible !important;
    position: relative;
  }

  .equity-container {
    height: auto !important;
  }

  .dynamic-content {
    height: auto !important;
    overflow: visible !important;
    top: 0 !important;
    scroll-padding-top: 0 !important;
  }

  coin-v2-button,
  app-equity-header,
  app-equity-chapter-indicator,
  app-footer,
  app-header,
  app-sidebar,
  ion-header,
  .simplebar-track,
  .cp-slide-toggle,
  coin-equity-cms-chapter-overview,
  .enlarge-icon {
    display: none !important;
  }

  coin-siemens-energy-content-pages {
    .header {
      display: none !important;
    }
  }

  app-equity-cards {
    .container {
      grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
  }

  .expand-box-content {
    transition: none !important;
    padding-top: 50px !important;
    max-height: 1000px !important;
  }

  app-equity-cards,
  coin-equity-cms-chapter,
  coin-equity-cms-cards,
  coin-equity-cms-full-widthpart-with-image,
  app-equity-tiles {
    * {
      page-break-inside: avoid;
    }
  }

  * {
    overflow: visible !important;
    overflow-y: visible !important;
    --overflow: visible !important;
  }
}
